var EditBlocks = {
  init: function () {
    EditBlocks.toggleOpenEditBlock(),
    EditBlocks.editTitleBlockEvent()
    EditBlocks.editDescBlockEvent()
    EditBlocks.editTitleDescImgBlockEvent()
    EditBlocks.editSliderBlockEvent()
    EditBlocks.editImageBlockEvent()
    EditBlocks.editCodingBlockEvent()
    EditBlocks.editDetailBlockEvent()
    EditBlocks.editAmountPriceBlockEvent()
    EditBlocks.editFlyerBlockEvent();
    EditBlocks.editYoutubeBlock();
    EditBlocks.removeFlyerBlockImage();
    EditBlocks.handleBackTitleBlockEvent()
    EditBlocks.handleBackDescBlockEvent()
    EditBlocks.handleBackTitleDescImgBlockEvent()
  },
  toggleOpenEditBlock: function() {
    $(document).on('click', '.show-component .edit-btn', function() {
      const $parent = $(this).closest('.block-box')
      $parent.find('.show-component').addClass('d-none')
      $parent.find('.edit-component').removeClass('d-none')
    })

    $(document).on('click', '.edit-component .back-btn', function() {
      const $parent = $(this).closest('.block-box')
      $parent.find('.show-component').removeClass('d-none')
      $parent.find('.edit-component').addClass('d-none')
    })
  },

  editFlyerBlockEvent: function () {
    $(document).on('click', '.flyer-block .apply-btn', function () {
      const $parent = $(this).closest('.block-box');

      $parent.find('.edit-component .image-prev').each(function () {
        $parent.find(`.show-component .${$(this).attr('class').split(' ').join('.')}`).attr("style", $(this).attr('style'));
        if ($(this).closest('.picture-block').find('input[type=file]').val() == '' && !$(this).closest('.picture-block').find('input[type=file]').data('has-value')) {
          $(this).closest('.picture-block').find('input[type=checkbox]').attr('checked', true);
        } else {
          $(this).closest('.picture-block').find('input[type=checkbox]').attr('checked', false);
        }
      });

      EditBlocks.handleBackBtn($parent);
    });
  },

  removeFlyerBlockImage: function () {
    $(document).on('click', '.flyer-block .remove-flyer-block-image-btn', function (e) {
      e.preventDefault();

      $(this).parent().find('.remove-image').val('true');
      $(this).closest('.picture-block').find('#image_prev').attr('style', 'background-image:none');
      $(this).closest('.picture-block').find('input[type=file]').val('');
      $(this).closest('.picture-block').find('input[type=file]').data('has-value', false);
    });
  },

  editTitleBlockEvent: function() {
    $(document).on('click', '.title-block .apply-btn', function() {
      const $parent = $(this).closest('.block-box')
      const title = $parent.find('.edit-component iframe').contents().find('body').html()
      $parent.find('.show-component .title-block--title').html(title)
      EditBlocks.handleBackBtn($parent)
      $parent.find('.edit-component .desc-block--description').val(title)
    })
  },
  editDescBlockEvent: function() {
    $(document).on('click', '.desc-block .apply-btn', function() {
      const $parent = $(this).closest('.block-box')
      const description = $parent.find('.edit-component iframe').contents().find('body').html()
      $parent.find('.show-component .desc-block--description').html(description)
      EditBlocks.handleBackBtn($parent)
    })
  },
  editCodingBlockEvent: function() {
    $(document).on('click', '.coding-block .apply-btn', function() {
      const $parent = $(this).closest('.block-box')
      const description = $parent.find('.edit-component .coding-block--description').val();
      let code_tag = $('<code>');
      $parent.find('.show-component .coding-block--description').html($('<pre>').append(code_tag));
      code_tag.text(description);
      EditBlocks.handleBackBtn($parent)
    })
  },
  editTitleDescImgBlockEvent: function() {
    $(document).on('click', '.title-img-desc-block .apply-btn, .two-images-block .apply-btn', function() {
      const $parent = $(this).closest('.block-box')
      const $checkbox = $parent.find(".edit-component .is-display-point-icon")
      if ($checkbox.is(':checked')) {
        $parent.find(".show-component .is-display-point-icon").attr('checked', true)

      } else {
        $parent.find(".show-component .is-display-point-icon").attr('checked', false)
      }

      const arr = ['1', '2', '3', '4', '5', '6']
      $.each(arr, function(_, index) {
        const img_alt = $parent.find(`.edit-component .image${index}-alt`).val()
        const img_caption = $parent.find(`.edit-component .image${index}-caption`).val()
        const img_description = $parent.find(`.edit-component .image${index}-description`).val()
        const style = $parent.find(`.edit-component .image${index}`).attr("style")

        $parent.find(`.show-component .image${index}-alt`).prop("alt", img_alt)
        $parent.find(`.show-component .image${index}-alt`).val(img_alt)
        $parent.find(`.show-component .image${index}-caption`).val(img_caption)
        $parent.find(`.show-component .image${index}-description`).val(img_description)
        $parent.find(`.show-component .image${index}`).attr("style", style)
      });
      EditBlocks.handleBackBtn($parent)
    })
  },
  editSliderBlockEvent: function() {
    $(document).on('click', '.slider-block .apply-btn', function() {
      const $parent = $(this).closest('.block-box')
      const description = $parent.find(".edit-component .slider-block--description").val()
      $parent.find(".show-component .slider-block--description").html(description)

      $parent.find('.edit-component .img-box').each(function(index) {
        index += 1
        const img_alt = $parent.find(`.edit-component .image${index}-alt`).val()
        const style = $parent.find(`.edit-component .image${index}`).attr("style")

        $parent.find(`.show-component .image${index}-alt`).prop("alt", img_alt)
        $parent.find(`.show-component .image${index}`).attr("style", style)
      });

      EditBlocks.handleBackBtn($parent)
    })
  },
  editImageBlockEvent: function() {
    $(document).on('click', '.image-block .apply-btn', function() {
      const $parent = $(this).closest('.block-box')
      const imgAltPc = $parent.find(".edit-component .image-block--image-alt-pc").val()
      const stylePc = $parent.find(".edit-component .image-prev-pc").attr("style")
      $parent.find('.show-component .image-block--image-alt-pc').prop("alt", imgAltPc)
      $parent.find('.show-component .image-prev-pc').prop("style", stylePc)

      const imgAltSp = $parent.find(".edit-component .image-block--image-alt-sp").val()
      const styleSp = $parent.find(".edit-component .image-prev-sp").attr("style")
      $parent.find('.show-component .image-block--image-alt-sp').prop("alt", imgAltSp)
      $parent.find('.show-component .image-prev-sp').prop("style", styleSp)

      EditBlocks.handleBackBtn($parent)
    })
  },
  editDetailBlockEvent: function() {
    $(document).on('click', '.detail-block .apply-btn', function() {
      const $parent = $(this).closest('.block-box')

      const name = $parent.find(".edit-component .detail-block--name").val()
      $parent.find('.show-component .detail-block--name').html(name)

      const nameFurigana = $parent.find(".edit-component .detail-block--name-furigana").val()
      $parent.find('.show-component .detail-block--name-furigana').val(nameFurigana)

      const description = $parent.find(".edit-component .detail-block--description").val()
      $parent.find('.show-component .detail-block--description').val(description)

      const imgAlt = $parent.find(".edit-component .detail-block--image-alt").val()
      $parent.find('.show-component .detail-block--image').prop("alt", imgAlt)

      const price = $parent.find(".edit-component .detail-block--price").val()
      $parent.find('.show-component .detail-block--price').val(price)

      const priceType = $parent.find(".edit-component .detail-block--price-type option:selected").text()
      $parent.find('.show-component .detail-block--price-type').html(priceType)

      const taxType = $parent.find(".edit-component .detail-block--tax-type option:selected").text()
      $parent.find('.show-component .detail-block--tax-type').html(taxType)

      const style = $parent.find(`.edit-component #image_prev`).attr("style")
      $parent.find('.show-component #image_prev').prop("style", style)

      const arr = ['1', '2', '3', '4', '5', '6']
      $.each(arr, function(_, index) {
        const info = $parent.find(`.edit-component .detail-block--info${index}`).val()
        $parent.find(`.show-component .detail-block--info${index}`).val(info)
      });

      EditBlocks.handleBackBtn($parent)
    })
  },
  editAmountPriceBlockEvent: function() {
    $(document).on('click', '.amount-price-block .apply-btn', function() {
      const $parent = $(this).closest('.block-box')

      const description = $parent.find(".edit-component .amount-price-block--description").val()
      $parent.find('.show-component .amount-price-block--description').val(description)

      const price = $parent.find(".edit-component .amount-price-block--price").val()
      $parent.find('.show-component .amount-price-block--price').val(price)

      const priceType = $parent.find(".edit-component .amount-price-block--price-type option:selected").text()
      $parent.find('.show-component .amount-price-block--price-type').html(priceType)

      const taxType = $parent.find(".edit-component .amount-price-block--tax-type option:selected").text()
      $parent.find('.show-component .amount-price-block--tax-type').html(taxType)

      EditBlocks.handleBackBtn($parent)
    })
  },
  editYoutubeBlock: function() {
    $(document).on('click', '.youtube-block .apply-btn', function() {
      const $parent = $(this).closest('.block-box')
      const video_id = $parent.find('.edit-component .youtube-block--video').val()
      if (video_id)
        $parent.find('.show-component iframe').attr('src', `https://www.youtube.com/embed/${video_id}`)
      else
        $parent.find('.show-component iframe').attr('src', '')
      EditBlocks.handleBackBtn($parent)
    })
  },

  handleBackTitleBlockEvent: function() {
    $(document).on('click', '.title-block .back-btn', function() {
      const $parent = $(this).closest('.block-box')
      const title = $parent.find('.show-component .title-block--title').html()
      $parent.find('.edit-component iframe').contents().find('body').html(title)
      EditBlocks.handleBackBtn($parent)
    })
  },
  handleBackDescBlockEvent: function() {
    $(document).on('click', '.desc-block .back-btn', function() {
      const $parent = $(this).closest('.block-box')
      const description = $parent.find('.show-component .desc-block--description').html()
      $parent.find('.edit-component iframe').contents().find('body').html(description)
      EditBlocks.handleBackBtn($parent)
    })
  },
  handleBackTitleDescImgBlockEvent: function() {
    $(document).on('click', '.title-img-desc-block .back-btn', function() {
      const $parent = $(this).closest('.block-box')

      const arr = ['1', '2', '3', '4', '5', '6']
      $.each(arr, function(_, index) {
        const img_alt = $parent.find(`.show-component .image${index}-alt`).val()
        const img_caption = $parent.find(`.show-component .image${index}-caption`).val()
        const img_tag_ids = $parent.find(`.show-component .image${index}-tag_ids`).val()
        const style = $parent.find(`.show-component .image${index}`).attr("style")

        $parent.find(`.edit-component .image${index}-alt`).val(img_alt)
        $parent.find(`.edit-component .image${index}-caption`).val(img_caption)
        $parent.find(`.edit-component .image${index}-tag_ids`).val(img_tag_ids)
        $parent.find(`.edit-component .image${index}`).attr("style", style)
      });

      EditBlocks.handleBackBtn($parent)
    })
  },
  handleBackBtn: function (parent) {
    parent.find('.show-component').removeClass('d-none')
    parent.find('.edit-component').addClass('d-none')
  }
}

export default EditBlocks
